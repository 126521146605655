// routes
import { Icon } from '@iconify/react';
// import { PATH_DASHBOARD } from '../../../routes/paths';


const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'Partner View', path: '/dashboard/subAdmin', icon: <Icon icon="ri:dashboard-line" /> 
      },
      { title: 'Reports', icon: <Icon icon="ri:dashboard-line" /> ,
        children: [
          { title: 'Compilance Training', path: '/dashboard/CompilanceTraining', icon: <Icon icon="ri:dashboard-line" /> },
          { title: 'Partner Compilance Details', path: '/dashboard/PartnerCompilanceDetails', icon: <Icon icon="ant-design:flag-twotone" /> },
          { title: 'NVIDIA AI Enterprise pricing list', path: '/dashboard/NVIDIAAIEnterprisepricing', icon: <Icon icon="pepicons-pop:fire" /> },
          { title: 'NVIDIA VGPU Price list', path: '/dashboard/NVIDIAVGPUPricelist', icon: <Icon icon="game-icons:ninja-heroic-stance" /> },
          { title: 'OEM Sku list', path: '/dashboard/OEMSkulist', icon: <Icon icon="game-icons:ninja-heroic-stance" /> },
          { title: 'ALL GPU Genius Trainings', path: '/dashboard/GPUGeniusTrainings', icon: <Icon icon="game-icons:ninja-heroic-stance" /> },
          { title: 'Partner Project List', path: '/dashboard/PartnerProjectList', icon: <Icon icon="game-icons:ninja-heroic-stance" /> },
          { title: 'Partner Training Compliance', path: '/dashboard/PartnerTrainingCompilance', icon: <Icon icon="fluent:contact-card-16-regular" /> },
          { title: 'Partner YoY', path: '/dashboard/PartnerYoyCompetency', icon: <Icon icon="fa6-brands:elementor" /> },
          { title: 'Partner YoY rev by PFAM', path: '/dashboard/PartnerYoYPFAM', icon: <Icon icon="game-icons:ninja-heroic-stance" /> },
          { title: 'Partner Training Suggestion', path: '/dashboard/PartnerTrainingSuggestion', icon: <Icon icon="solar:tag-linear" /> },
          { title: 'POS VS Threshold', path: '/dashboard/POSVSThreshold', icon: <Icon icon="game-icons:magic-axe" /> },
          { title: 'Use Cases', path: '/dashboard/UseCases', icon: <Icon icon="fontisto:world" /> },
          { title: 'Software Order Full', path: '/dashboard/SoftwareOrderFull', icon: <Icon icon="game-icons:ninja-heroic-stance" /> },
        ],
      },
      {
        title: 'Disscussion Board', path: '/dashboard/Disscussion', icon: <Icon icon="ri:dashboard-line" /> 
      },
    ],
  },

  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // E-COMMERCE
  // {
  //   title: 'e-commerce',
  //   path: PATH_DASHBOARD.eCommerce.root,
  //   icon: ICONS.cart,
  //   children: [
  //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //   ],
  // },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  // BLOG
  // {
  //   title: 'blog',
  //   path: PATH_DASHBOARD.blog.root,
  //   icon: ICONS.blog,
  //   children: [
  //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //     { title: 'create', path: PATH_DASHBOARD.blog.new },
  //   ],
  // },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },
];

export default navConfig;
