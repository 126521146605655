import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axiosInstance from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  PartnerTrainingSuggestion: [],
};


const slice = createSlice({
  name: 'PartnerTrainingSuggestion',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET elementS
    getPartnerTrainingSuggestionSuccess(state, action) {
      state.isLoading = false;
      state.PartnerTrainingSuggestion = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getPartnerTrainingSuggestion() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('partner-training-suggestion');
      dispatch(slice.actions.getPartnerTrainingSuggestionSuccess(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

