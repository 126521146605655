import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import productReducer from './slices/product';
import CompilancetrainingReducer from './slices/CompilanceTraining';
import PartnerCompilanceDetailsReducer from './slices/PartnerCompilanceDetails';
import NVIDIAAIEnterprisepricingReducer from './slices/NVIDIAAIEnterprisepricing';
import NVIDIAVGPUPricelistReducer from './slices/NVIDIAVGPUPricelist';
import OEMSkulistReducer from './slices/OEMSkulist';
import GPUGeniusTrainingsReducer from './slices/GPUGeniusTrainings';
import PartnerProjectListReducer from './slices/PartnerProjectList';
import PartnerTrainingCompilanceReducer from './slices/PartnerTrainingCompilance';
import PartnerYoyCompetencyReducer from './slices/PartnerYoyCompetency';
import PartnerYoYPFAMReducer from './slices/PartnerYoYPFAM';
import PartnerTrainingSuggestionReducer from './slices/Partner Training Suggestion';
import POSVSThresholdReducer from './slices/POSVSThreshold';
import UseCasesReducer from './slices/UseCases';
import SoftwareOrderFullReducer from './slices/SoftwareOrderFull';
import SubAdminReducer from './slices/SubAdmin';
import magictypeReducer from './slices/magictype';
import tagReducer from './slices/tag';
import cardReducer from './slices/card';
import rarityReducer from './slices/rarity';
import spellReducer from './slices/spell';
import characterReducer from './slices/character';

const rootPersistConfig = {
  key: 'root',
  storage,
  // keyPrefix: 'redux-',
  // whitelist: [],
};


const rootReducer = combineReducers({
  Compilancetraining:CompilancetrainingReducer,
  PartnerCompilanceDetails:PartnerCompilanceDetailsReducer,
  NVIDIAAIEnterprisepricing:NVIDIAAIEnterprisepricingReducer,
  NVIDIAVGPUPricelist:NVIDIAVGPUPricelistReducer,
  OEMSkulist:OEMSkulistReducer,
  GPUGeniusTrainings:GPUGeniusTrainingsReducer,
  PartnerProjectList:PartnerProjectListReducer,
  PartnerTrainingCompilance:PartnerTrainingCompilanceReducer,
  PartnerYoyCompetency:PartnerYoyCompetencyReducer,
  PartnerYoYPFAM:PartnerYoYPFAMReducer,
  PartnerTrainingSuggestion:PartnerTrainingSuggestionReducer,
  POSVSThreshold:POSVSThresholdReducer,
  UseCases:UseCasesReducer,
  SoftwareOrderFull:SoftwareOrderFullReducer,
  SubAdmin:SubAdminReducer,
  magictype:magictypeReducer,
  tag:tagReducer,
  card:cardReducer,
  rarity:rarityReducer,
  spell:spellReducer,
  character:characterReducer,
  product: productReducer,
});

export { rootPersistConfig, rootReducer };
