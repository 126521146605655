import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axiosInstance from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  SubAdmin: [],
};


const slice = createSlice({
  name: 'SubAdmin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET elementS
    getSubAdminSuccess(state, action) {
      state.isLoading = false;
      state.SubAdmin = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getSubAdmin() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('/subadmin');
      dispatch(slice.actions.getSubAdminSuccess(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

