import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
// import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
// import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home/> },
    { path: '/login/admin', element: <Login/> },
    { path: '/login/subadmin', element: <SubadminLogin/> },
    { path: '/dashboard', element: <GeneralApp/> },
    { path: '/reset-passsword', element: <ResetPassword /> },
    { path: '/verify', element: <VerifyCode /> },
    { path: '/new-password', element: <SetPassword /> },
    {
          path: 'dashboard',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [

            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'home', element: <GeneralAnalytics /> },
            { path: 'dorm', element: <Dorm /> },
            { path: 'editdorm/:id', element: <EditDorm /> },
            { path: 'adddorm', element: <AddDorm /> },
            { path: 'CompilanceTraining', element: <CompilanceTraining /> },
            { path: 'editCompilanceTraining/:id', element: <EditCompilanceTraining /> },
            { path: 'AddCompilanceTraining', element: <AddCompilanceTraining /> },
            { path: 'PartnerCompilanceDetails', element: <PartnerCompilanceDetails /> },
            { path: 'editPartnerCompilanceDetails/:id', element: <EditPartnerCompilanceDetails /> },
            { path: 'AddPartnerCompilanceDetails', element: <AddPartnerCompilanceDetails /> },
            { path: 'NVIDIAAIEnterprisepricing', element: <NVIDIAAIEnterprisepricing /> },
            { path: 'editNVIDIAAIEnterprisepricing/:id', element: <EditNVIDIAAIEnterprisepricing /> },
            { path: 'AddNVIDIAAIEnterprisepricing', element: <AddNVIDIAAIEnterprisepricing /> },
            { path: 'NVIDIAVGPUPricelist', element: <NVIDIAVGPUPricelist /> },
            { path: 'editNVIDIAVGPUPricelist/:id', element: <EditNVIDIAVGPUPricelist /> },
            { path: 'AddNVIDIAVGPUPricelist', element: <AddNVIDIAVGPUPricelist /> },
            { path: 'OEMSkulist', element: <OEMSkulist /> },
            { path: 'editOEMSkulist/:id', element: <EditOEMSkulist /> },
            { path: 'AddOEMSkulist', element: <AddOEMSkulist /> },
            { path: 'GPUGeniusTrainings', element: <GPUGeniusTrainings /> },
            { path: 'editGPUGeniusTrainings/:id', element: <EditGPUGeniusTrainings /> },
            { path: 'AddGPUGeniusTrainings', element: <AddGPUGeniusTrainings /> },
            { path: 'PartnerProjectList', element: <PartnerProjectList /> },
            { path: 'editPartnerProjectList/:id', element: <EditPartnerProjectList /> },
            { path: 'AddPartnerProjectList', element: <AddPartnerProjectList /> },
            { path: 'PartnerTrainingCompilance', element: <PartnerTrainingCompilance /> },
            { path: 'editPartnerTrainingCompilance/:id', element: <EditPartnerTrainingCompilance /> },
            { path: 'AddPartnerTrainingCompilance', element: <AddPartnerTrainingCompilance /> },
            { path: 'PartnerYoyCompetency', element: <PartnerYoyCompetency /> },
            { path: 'editPartnerYoyCompetency/:id', element: <EditPartnerYoyCompetency /> },
            { path: 'AddPartnerYoyCompetency', element: <AddPartnerYoyCompetency /> },
            { path: 'PartnerYoYPFAM', element: <PartnerYoYPFAM /> },
            { path: 'editPartnerYoYPFAM/:id', element: <EditPartnerYoYPFAM /> },
            { path: 'AddPartnerYoYPFAM', element: <AddPartnerYoYPFAM /> },
            { path: 'PartnerTrainingSuggestion', element: <PartnerTrainingSuggestion /> },
            { path: 'editPartnerTrainingSuggestion/:id', element: <EditPartnerTrainingSuggestion /> },
            { path: 'AddPartnerTrainingSuggestion', element: <AddPartnerTrainingSuggestion /> },
            { path: 'POSVSThreshold', element: <POSVSThreshold /> },
            { path: 'editPOSVSThreshold/:id', element: <EditPOSVSThreshold /> },
            { path: 'AddPOSVSThreshold', element: <AddPOSVSThreshold /> },
            { path: 'UseCases', element: <UseCases /> },
            { path: 'editUseCases/:id', element: <EditUseCases /> },
            { path: 'AddUseCases', element: <AddUseCases /> },
            { path: 'SoftwareOrderFull', element: <SoftwareOrderFull /> },
            { path: 'editSoftwareOrderFull/:id', element: <EditSoftwareOrderFull /> },
            { path: 'AddSoftwareOrderFull', element: <AddSoftwareOrderFull /> },
            { path: 'SubAdmin', element: <SubAdmin /> },
            { path: 'editSubAdmin/:id', element: <EditSubAdmin /> },
            { path: 'AddSubAdmin', element: <AddSubAdmin /> },
            { path: 'Disscussion', element: <Disscussion /> },
            { path: 'editDisscussion/:id', element: <EditDisscussion /> },
            { path: 'AddDisscussion', element: <AddDisscussion /> },
            { path: 'magictype', element: <Magictype /> },
            { path: 'editmagictype/:id', element: <EditMagictype /> },
            { path: 'addmagictype', element: <AddMagictype /> },
            { path: 'tag', element: <Tag/> },
            { path: 'edittag/:id', element: <EditTag /> },
            { path: 'addtag', element: <AddTag /> },
            { path: 'rarity', element: <Rarity /> },
            { path: 'editrarity/:id', element: <EditRarity /> },
            { path: 'addrarity', element: <AddRarity /> },
             { path: 'spell', element: <Spell /> },
             { path: 'editspell/:id', element: <EditSpell /> },
             { path: 'addspell', element: <AddSpell /> },
             { path: 'spelleffect/:id', element: <Effect/> },
             { path: 'card', element: <Card /> },
            { path: 'editcard/:id', element: <EditCard /> },
            { path: 'addcard', element: <AddCard /> },
             { path: 'character', element: <Character /> },
            { path: 'editcharacter/:id', element: <EditCharacter /> },
            { path: 'addcharacter', element: <AddCharacter /> },
            { path: 'CardDetail/:id', element: <Profile /> },



            { path: 'ecommerce', element: <GeneralEcommerce /> },
            
            { path: 'banking', element: <GeneralBanking /> },
            { path: 'booking', element: <GeneralBooking /> },
    
            {
              path: 'e-commerce',
              children: [
                { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
                { path: 'shop', element: <EcommerceShop /> },
                { path: 'product/:name', element: <EcommerceProductDetails /> },
                { path: 'list', element: <EcommerceProductList /> },
                { path: 'product/new', element: <EcommerceProductCreate /> },
                { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
                { path: 'checkout', element: <EcommerceCheckout /> },
              ],
            },
          ],
        },
  //   {
  //     path: 'auth',
  //     children: [
  //       {
  //         path: 'login',
  //         element: (
  //           <GuestGuard>
  //             <Login />
  //           </GuestGuard>
  //         ),
  //       },
  //       {
  //         path: 'register',
  //         element: (
  //           <GuestGuard>
  //             <Register />
  //           </GuestGuard>
  //         ),
  //       },
  //       { path: 'login-unprotected', element: <Login /> },
  //       { path: 'register-unprotected', element: <Register /> },
  //       { path: 'reset-password', element: <ResetPassword /> },
  //       { path: 'verify', element: <VerifyCode /> },
  //     ],
  //   },

  //   // Dashboard Routes
  //   {
  //     path: 'dashboard',
  //     element: (
  //       <AuthGuard>
  //         <DashboardLayout />
  //       </AuthGuard>
  //     ),
  //     children: [
  //       { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
  //       { path: 'dom', element: <Dom /> },
  //       { path: 'ecommerce', element: <GeneralEcommerce /> },
  //       { path: 'analytics', element: <GeneralAnalytics /> },
  //       { path: 'banking', element: <GeneralBanking /> },
  //       { path: 'booking', element: <GeneralBooking /> },

        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShop /> },
        //     { path: 'product/:name', element: <EcommerceProductDetails /> },
        //     { path: 'list', element: <EcommerceProductList /> },
        //     { path: 'product/new', element: <EcommerceProductCreate /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
        //     { path: 'checkout', element: <EcommerceCheckout /> },
        //   ],
        // },
  //       {
  //         path: 'user',
  //         children: [
  //           { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
  //           { path: 'profile', element: <UserProfile /> },
  //           { path: 'cards', element: <UserCards /> },
  //           { path: 'list', element: <UserList /> },
  //           { path: 'new', element: <UserCreate /> },
  //           { path: ':name/edit', element: <UserCreate /> },
  //           { path: 'account', element: <UserAccount /> },
  //         ],
  //       },
  //       {
  //         path: 'invoice',
  //         children: [
  //           { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
  //           { path: 'list', element: <InvoiceList /> },
  //           { path: ':id', element: <InvoiceDetails /> },
  //           { path: ':id/edit', element: <InvoiceEdit /> },
  //           { path: 'new', element: <InvoiceCreate /> },
  //         ],
  //       },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPosts /> },
        //     { path: 'post/:title', element: <BlogPost /> },
        //     { path: 'new', element: <BlogNewPost /> },
        //   ],
        // },
  //       {
  //         path: 'mail',
  //         children: [
  //           { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
  //           { path: 'label/:customLabel', element: <Mail /> },
  //           { path: 'label/:customLabel/:mailId', element: <Mail /> },
  //           { path: ':systemLabel', element: <Mail /> },
  //           { path: ':systemLabel/:mailId', element: <Mail /> },
  //         ],
  //       },
  //       {
  //         path: 'chat',
  //         children: [
  //           { element: <Chat />, index: true },
  //           { path: 'new', element: <Chat /> },
  //           { path: ':conversationKey', element: <Chat /> },
  //         ],
  //       },
  //       { path: 'calendar', element: <Calendar /> },
  //       { path: 'kanban', element: <Kanban /> },
  //     ],
  //   },

  //   // Main Routes
  //   {
  //     path: '*',
  //     element: <LogoOnlyLayout />,
  //     children: [
  //       { path: 'coming-soon', element: <ComingSoon /> },
  //       { path: 'maintenance', element: <Maintenance /> },
  //       { path: 'pricing', element: <Pricing /> },
  //       { path: 'payment', element: <Payment /> },
  //       { path: '500', element: <Page500 /> },
  //       { path: '404', element: <NotFound /> },
  //       { path: '*', element: <Navigate to="/404" replace /> },
  //     ],
  //   },
  //   {
  //     path: '/',
  //     element: <Login/>,
  //     children: [
  //       { element: <HomePage />, index: true },
  //       { path: 'about-us', element: <About /> },
  //       { path: 'contact-us', element: <Contact /> },
  //       { path: 'faqs', element: <Faqs /> },
  //     ],
  //   },
  //   { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const SubadminLogin = Loadable(lazy(() => import('../pages/auth/SubadminLogin')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const SetPassword = Loadable(lazy(() => import('../pages/auth/SetPassword')));


const Dorm = Loadable(lazy(() => import('../pages/dashboard/Dorm/Dorm')));
const EditDorm = Loadable(lazy(() => import('../pages/dashboard/Dorm/EditDorm')));
const AddDorm = Loadable(lazy(() => import('../pages/dashboard/Dorm/AddDorm')));

const CompilanceTraining = Loadable(lazy(() => import('../pages/dashboard/Compilance Training/CompilanceTraining')));
const EditCompilanceTraining = Loadable(lazy(() => import('../pages/dashboard/Compilance Training/EditCompilanceTraining')));
const AddCompilanceTraining = Loadable(lazy(() => import('../pages/dashboard/Compilance Training/AddCompilanceTraining')));

const PartnerCompilanceDetails = Loadable(lazy(() => import('../pages/dashboard/Partner Compilance Details/PartnerCompilanceDetails')));
const EditPartnerCompilanceDetails = Loadable(lazy(() => import('../pages/dashboard/Partner Compilance Details/EditPartnerCompilanceDetails')));
const AddPartnerCompilanceDetails = Loadable(lazy(() => import('../pages/dashboard/Partner Compilance Details/AddPartnerCompilanceDetails')));

const NVIDIAAIEnterprisepricing = Loadable(lazy(() => import('../pages/dashboard/NVIDIA AI Enterprise pricing/NVIDIAAIEnterprisepricing')));
const EditNVIDIAAIEnterprisepricing = Loadable(lazy(() => import('../pages/dashboard/NVIDIA AI Enterprise pricing/EditNVIDIAAIEnterprisepricing')));
const AddNVIDIAAIEnterprisepricing = Loadable(lazy(() => import('../pages/dashboard/NVIDIA AI Enterprise pricing/AddNVIDIAAIEnterprisepricing')));

const NVIDIAVGPUPricelist = Loadable(lazy(() => import('../pages/dashboard/NVIDIA VGPU Price list/NVIDIAVGPUPricelist')));
const EditNVIDIAVGPUPricelist = Loadable(lazy(() => import('../pages/dashboard/NVIDIA VGPU Price list/EditNVIDIAVGPUPricelist')));
const AddNVIDIAVGPUPricelist = Loadable(lazy(() => import('../pages/dashboard/NVIDIA VGPU Price list/AddNVIDIAVGPUPricelist')));

const OEMSkulist = Loadable(lazy(() => import('../pages/dashboard/OEM Sku list/OEMSkulist')));
const EditOEMSkulist = Loadable(lazy(() => import('../pages/dashboard/OEM Sku list/EditOEMSkulist')));
const AddOEMSkulist = Loadable(lazy(() => import('../pages/dashboard/OEM Sku list/AddOEMSkulist')));

const GPUGeniusTrainings = Loadable(lazy(() => import('../pages/dashboard/GPU Genius Trainings/GPUGeniusTrainings')));
const EditGPUGeniusTrainings = Loadable(lazy(() => import('../pages/dashboard/GPU Genius Trainings/EditGPUGeniusTrainings')));
const AddGPUGeniusTrainings = Loadable(lazy(() => import('../pages/dashboard/GPU Genius Trainings/AddGPUGeniusTrainings')));

const PartnerProjectList = Loadable(lazy(() => import('../pages/dashboard/Partner Project List/PartnerProjectList')));
const EditPartnerProjectList = Loadable(lazy(() => import('../pages/dashboard/Partner Project List/EditPartnerProjectList')));
const AddPartnerProjectList = Loadable(lazy(() => import('../pages/dashboard/Partner Project List/AddPartnerProjectList')));

const PartnerTrainingCompilance = Loadable(lazy(() => import('../pages/dashboard/Partner Training Compilance/PartnerTrainingCompilance')));
const EditPartnerTrainingCompilance = Loadable(lazy(() => import('../pages/dashboard/Partner Training Compilance/EditPartnerTrainingCompilance')));
const AddPartnerTrainingCompilance = Loadable(lazy(() => import('../pages/dashboard/Partner Training Compilance/AddPartnerTrainingCompilance')));

const PartnerYoyCompetency = Loadable(lazy(() => import('../pages/dashboard/Partner YOY Competency/PartnerYoyCompetency')));
const EditPartnerYoyCompetency = Loadable(lazy(() => import('../pages/dashboard/Partner YOY Competency/EditPartnerYoyCompetency')));
const AddPartnerYoyCompetency = Loadable(lazy(() => import('../pages/dashboard/Partner YOY Competency/AddPartnerYoyCompetency')));

const PartnerYoYPFAM = Loadable(lazy(() => import('../pages/dashboard/Partner YoY PFAM/PartnerYoYPFAM')));
const EditPartnerYoYPFAM = Loadable(lazy(() => import('../pages/dashboard/Partner YoY PFAM/EditPartnerYoYPFAM')));
const AddPartnerYoYPFAM = Loadable(lazy(() => import('../pages/dashboard/Partner YoY PFAM/AddPartnerYoYPFAM')));

const PartnerTrainingSuggestion = Loadable(lazy(() => import('../pages/dashboard/Partner Training Suggestion/PartnerTrainingSuggestion')));
const EditPartnerTrainingSuggestion = Loadable(lazy(() => import('../pages/dashboard/Partner Training Suggestion/EditPartnerTrainingSuggestion')));
const AddPartnerTrainingSuggestion = Loadable(lazy(() => import('../pages/dashboard/Partner Training Suggestion/AddPartnerTrainingSuggestion')));

const POSVSThreshold = Loadable(lazy(() => import('../pages/dashboard/POS VS Threshold/POSVSThreshold')));
const EditPOSVSThreshold = Loadable(lazy(() => import('../pages/dashboard/POS VS Threshold/EditPOSVSThreshold')));
const AddPOSVSThreshold = Loadable(lazy(() => import('../pages/dashboard/POS VS Threshold/AddPOSVSThreshold')));

const UseCases = Loadable(lazy(() => import('../pages/dashboard/Use Cases/UseCases')));
const EditUseCases = Loadable(lazy(() => import('../pages/dashboard/Use Cases/EditUseCases')));
const AddUseCases = Loadable(lazy(() => import('../pages/dashboard/Use Cases/AddUseCases')));

const SoftwareOrderFull = Loadable(lazy(() => import('../pages/dashboard/Software Order Full/SoftwareOrderFull')));
const EditSoftwareOrderFull = Loadable(lazy(() => import('../pages/dashboard/Software Order Full/EditSoftwareOrderFull')));
const AddSoftwareOrderFull = Loadable(lazy(() => import('../pages/dashboard/Software Order Full/AddSoftwareOrderFull')));

const SubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/SubAdmin')));
const EditSubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/EditSubAdmin')));
const AddSubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/AddSubAdmin')));

const Disscussion = Loadable(lazy(() => import('../pages/dashboard/Disscussion/Disscussion')));
const EditDisscussion = Loadable(lazy(() => import('../pages/dashboard/Disscussion/EditDisscussion')));
const AddDisscussion = Loadable(lazy(() => import('../pages/dashboard/Disscussion/AddDisscussion')));

const Magictype = Loadable(lazy(() => import('../pages/dashboard/Magictype/Magictype')));
const EditMagictype = Loadable(lazy(() => import('../pages/dashboard/Magictype/EditMagictype')));
const AddMagictype = Loadable(lazy(() => import('../pages/dashboard/Magictype/AddMagictype')));

const Tag = Loadable(lazy(() => import('../pages/dashboard/Tag/Tag')));
const EditTag = Loadable(lazy(() => import('../pages/dashboard/Tag/EditTag')));
const AddTag = Loadable(lazy(() => import('../pages/dashboard/Tag/AddTag')));

const Rarity = Loadable(lazy(() => import('../pages/dashboard/Rarity/Rarity')));
const EditRarity = Loadable(lazy(() => import('../pages/dashboard/Rarity/EditRarity')));
const AddRarity = Loadable(lazy(() => import('../pages/dashboard/Rarity/AddRarity')));

const Spell = Loadable(lazy(() => import('../pages/dashboard/Spell/Spell')));

const EditSpell = Loadable(lazy(() => import('../pages/dashboard/Spell/EditSpell')));
const AddSpell = Loadable(lazy(() => import('../pages/dashboard/Spell/AddSpell')));
const Effect = Loadable(lazy(() => import('../pages/dashboard/Spell/Effect')));

const Card = Loadable(lazy(() => import('../pages/dashboard/Card/Card')));
const EditCard = Loadable(lazy(() => import('../pages/dashboard/Card/EditCard')));
const AddCard = Loadable(lazy(() => import('../pages/dashboard/Card/AddCard')));
// const CardDetail = Loadable(lazy(() => import('../pages/dashboard/Card/CardDetails')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/Card/Profile')));

const Character = Loadable(lazy(() => import('../pages/dashboard/Character/Character')));
const EditCharacter = Loadable(lazy(() => import('../pages/dashboard/Character/EditCharacter')));
const AddCharacter = Loadable(lazy(() => import('../pages/dashboard/Character/AddCharacter')));

const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// // INVOICE
// const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
// const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
// const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
// const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
// const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
// const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// // USER
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// // APP
// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// // MAIN
const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
// const Page500 = Loadable(lazy(() => import('../pages/Page500')));
// const NotFound = Loadable(lazy(() => import('../pages/Page404')));
