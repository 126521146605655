import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axiosInstance from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  PartnerProjectList: [],
};


const slice = createSlice({
  name: 'PartnerProjectList',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET elementS
    getPartnerProjectListSuccess(state, action) {
      state.isLoading = false;
      state.PartnerProjectList = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getPartnerProjectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('partner-project-list');
      dispatch(slice.actions.getPartnerProjectListSuccess(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

