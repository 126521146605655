// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ROLE_LOGIN = '/login';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_ROLE_LOGIN = {
  root: ROOTS_ROLE_LOGIN,
  admin: path(ROOTS_ROLE_LOGIN, '/admin'),
  subadmin: path(ROOTS_ROLE_LOGIN, `/subadmin`),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dorm: {
    app: path(ROOTS_DASHBOARD, '/'),
    dorm: path(ROOTS_DASHBOARD, '/dorm'),
    editdorm: (id) => path(ROOTS_DASHBOARD, `/editdorm/${id}`),
    adddorm: path(ROOTS_DASHBOARD, '/adddorm'),
  },
  CompilanceTraining: {
    app: path(ROOTS_DASHBOARD, '/'),
    CompilanceTraining: path(ROOTS_DASHBOARD, '/CompilanceTraining'),
    editCompilanceTraining: (id) => path(ROOTS_DASHBOARD, `/editCompilanceTraining/${id}`),
    AddCompilanceTraining: path(ROOTS_DASHBOARD, '/AddCompilanceTraining'),
  },
  PartnerCompilanceDetails: {
    app: path(ROOTS_DASHBOARD, '/'),
    PartnerCompilanceDetails: path(ROOTS_DASHBOARD, '/PartnerCompilanceDetails'),
    editPartnerCompilanceDetails: (id) => path(ROOTS_DASHBOARD, `/editPartnerCompilanceDetails/${id}`),
    AddPartnerCompilanceDetails: path(ROOTS_DASHBOARD, '/AddPartnerCompilanceDetails'),
  },
  NVIDIAAIEnterprisepricing: {
    app: path(ROOTS_DASHBOARD, '/'),
    NVIDIAAIEnterprisepricing: path(ROOTS_DASHBOARD, '/NVIDIAAIEnterprisepricing'),
    editNVIDIAAIEnterprisepricing: (id) => path(ROOTS_DASHBOARD, `/editNVIDIAAIEnterprisepricing/${id}`),
    AddNVIDIAAIEnterprisepricing: path(ROOTS_DASHBOARD, '/AddNVIDIAAIEnterprisepricing'),
  },
  NVIDIAVGPUPricelist: {
    app: path(ROOTS_DASHBOARD, '/'),
    NVIDIAVGPUPricelist: path(ROOTS_DASHBOARD, '/NVIDIAVGPUPricelist'),
    editNVIDIAVGPUPricelist: (id) => path(ROOTS_DASHBOARD, `/editNVIDIAVGPUPricelist/${id}`),
    AddNVIDIAVGPUPricelist: path(ROOTS_DASHBOARD, '/AddNVIDIAVGPUPricelist'),
  },
  OEMSkulist: {
    app: path(ROOTS_DASHBOARD, '/'),
    OEMSkulist: path(ROOTS_DASHBOARD, '/OEMSkulist'),
    editOEMSkulist: (id) => path(ROOTS_DASHBOARD, `/editOEMSkulist/${id}`),
    AddOEMSkulist: path(ROOTS_DASHBOARD, '/AddOEMSkulist'),
  },
  GPUGeniusTrainings: {
    app: path(ROOTS_DASHBOARD, '/'),
    GPUGeniusTrainings: path(ROOTS_DASHBOARD, '/GPUGeniusTrainings'),
    editGPUGeniusTrainings: (id) => path(ROOTS_DASHBOARD, `/editGPUGeniusTrainings/${id}`),
    AddGPUGeniusTrainings: path(ROOTS_DASHBOARD, '/AddGPUGeniusTrainings'),
  },
  PartnerProjectList: {
    app: path(ROOTS_DASHBOARD, '/'),
    PartnerProjectList: path(ROOTS_DASHBOARD, '/PartnerProjectList'),
    editPartnerProjectList: (id) => path(ROOTS_DASHBOARD, `/editPartnerProjectList/${id}`),
    AddPartnerProjectList: path(ROOTS_DASHBOARD, '/AddPartnerProjectList'),
  },
  PartnerTrainingCompilance: {
    app: path(ROOTS_DASHBOARD, '/'),
    PartnerTrainingCompilance: path(ROOTS_DASHBOARD, '/PartnerTrainingCompilance'),
    editPartnerTrainingCompilance: (id) => path(ROOTS_DASHBOARD, `/editPartnerTrainingCompilance/${id}`),
    AddPartnerTrainingCompilance: path(ROOTS_DASHBOARD, '/AddPartnerTrainingCompilance'),
  },
  PartnerYoyCompetency: {
    app: path(ROOTS_DASHBOARD, '/'),
    PartnerYoyCompetency: path(ROOTS_DASHBOARD, '/PartnerYoyCompetency'),
    editPartnerYoyCompetency: (id) => path(ROOTS_DASHBOARD, `/editPartnerYoyCompetency/${id}`),
    AddPartnerYoyCompetency: path(ROOTS_DASHBOARD, '/AddPartnerYoyCompetency'),
  },
  PartnerYoYPFAM: {
    app: path(ROOTS_DASHBOARD, '/'),
    PartnerYoYPFAM: path(ROOTS_DASHBOARD, '/PartnerYoYPFAM'),
    editPartnerYoYPFAM: (id) => path(ROOTS_DASHBOARD, `/editPartnerYoYPFAM/${id}`),
    AddPartnerYoYPFAM: path(ROOTS_DASHBOARD, '/AddPartnerYoYPFAM'),
  },
  PartnerTrainingSuggestion: {
    app: path(ROOTS_DASHBOARD, '/'),
    PartnerTrainingSuggestion: path(ROOTS_DASHBOARD, '/PartnerTrainingSuggestion'),
    editPartnerTrainingSuggestion: (id) => path(ROOTS_DASHBOARD, `/editPartnerTrainingSuggestion/${id}`),
    AddPartnerTrainingSuggestion: path(ROOTS_DASHBOARD, '/AddPartnerTrainingSuggestion'),
  },
  POSVSThreshold: {
    app: path(ROOTS_DASHBOARD, '/'),
    POSVSThreshold: path(ROOTS_DASHBOARD, '/POSVSThreshold'),
    editPOSVSThreshold: (id) => path(ROOTS_DASHBOARD, `/editPOSVSThreshold/${id}`),
    AddPOSVSThreshold: path(ROOTS_DASHBOARD, '/AddPOSVSThreshold'),
  },
  UseCases: {
    app: path(ROOTS_DASHBOARD, '/'),
    UseCases: path(ROOTS_DASHBOARD, '/UseCases'),
    editUseCases: (id) => path(ROOTS_DASHBOARD, `/editUseCases/${id}`),
    AddUseCases: path(ROOTS_DASHBOARD, '/AddUseCases'),
  },
  SoftwareOrderFull: {
    app: path(ROOTS_DASHBOARD, '/'),
    SoftwareOrderFull: path(ROOTS_DASHBOARD, '/SoftwareOrderFull'),
    editSoftwareOrderFull: (id) => path(ROOTS_DASHBOARD, `/editSoftwareOrderFull/${id}`),
    AddSoftwareOrderFull: path(ROOTS_DASHBOARD, '/AddSoftwareOrderFull'),
  },
  SubAdmin: {
    app: path(ROOTS_DASHBOARD, '/'),
    SubAdmin: path(ROOTS_DASHBOARD, '/SubAdmin'),
    editSubAdmin: (id) => path(ROOTS_DASHBOARD, `/editSubAdmin/${id}`),
    AddSubAdmin: path(ROOTS_DASHBOARD, '/AddSubAdmin'),
  },
  Disscussion: {
    app: path(ROOTS_DASHBOARD, '/'),
    Disscussion: path(ROOTS_DASHBOARD, '/Disscussion'),
    editDisscussion: (id) => path(ROOTS_DASHBOARD, `/editDisscussion/${id}`),
    AddDisscussion: path(ROOTS_DASHBOARD, '/AddDisscussion'),
  },
  magictype: {
    app: path(ROOTS_DASHBOARD, '/'),
    magictype: path(ROOTS_DASHBOARD, '/magictype'),
    editmagictype: (id) => path(ROOTS_DASHBOARD, `/editmagictype/${id}`),
    addmagictype: path(ROOTS_DASHBOARD, '/addmagictype'),
  },
  tag: {
    app: path(ROOTS_DASHBOARD, '/'),
    tag: path(ROOTS_DASHBOARD, '/tag'),
    edittag: (id) => path(ROOTS_DASHBOARD, `/edittag/${id}`),
    addtag: path(ROOTS_DASHBOARD, '/addtag'),
  },
  rarity: {
    app: path(ROOTS_DASHBOARD, '/'),
    rarity: path(ROOTS_DASHBOARD, '/rarity'),
    editrarity: (id) => path(ROOTS_DASHBOARD, `/editrarity/${id}`),
    addrarity: path(ROOTS_DASHBOARD, '/addrarity'),
  },
  spell: {
    app: path(ROOTS_DASHBOARD, '/'),
    spell: path(ROOTS_DASHBOARD, '/spell'),
    editspell: (id) => path(ROOTS_DASHBOARD, `/editspell/${id}`),
    addspell: path(ROOTS_DASHBOARD, '/addspell'),
    spelleffect:(id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  character: {
    app: path(ROOTS_DASHBOARD, '/'),
    character: path(ROOTS_DASHBOARD, '/character'),
    editcharacter: (id) => path(ROOTS_DASHBOARD, `/editcharacter/${id}`),
    addcharacter: path(ROOTS_DASHBOARD, '/addcharacter'),
  },
  card: {
    app: path(ROOTS_DASHBOARD, '/'),
    card: path(ROOTS_DASHBOARD, '/card'),
    editcard: (id) => path(ROOTS_DASHBOARD, `/editcard/${id}`),
    carddetail: (id) => path(ROOTS_DASHBOARD, `/carddetail/${id}`),
    addcard: path(ROOTS_DASHBOARD, '/addcard'),
  },


  general: {
    app: path(ROOTS_DASHBOARD, '/'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   new: path(ROOTS_DASHBOARD, '/blog/new'),
  //   view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
  //   demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  // },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
